import BasicService from "./BasicService";

class InvoiceService extends BasicService {
  constructor() {
    super();
  }

  getInvoiceById(invoiceId) {
    let url = `/invoice/detail`;
    return this.axios.get(url,{
      params:{
        invoiceId
      }
    }).then(response => response.data);
  }
  getReportInvoiceById(invoiceId) {
    let url = `/invoice/report/detail`;
    return this.axios.get(url,{
      params:{
        invoiceId
      }
    }).then(response => response.data);
  }
  getInvPaymentById(invoiceId,invPaymentId) {
    let url = `/invoice/invpaydtl/one`;
    return this.axios.get(url,{
      params:{
        invoiceId,invPaymentId
      }
    }).then(response => response.data);
  }
  getInvoice(fromDate,toDate,status,type) {
    let url = `/invoice`;
    return this.axios.get(url,{
      params:{
        fromDate,toDate,status,type
      }
    }).then(response => response.data);
  }
  getInvoiceListByCustomer(customerId,status,type) {   
    let url = `invoice/customer`;
    return this.axios.get(url,{
      params:{
        customerId,status,type
      }
    }).then(response => response.data);
  }
  addInvoice(invoice) {
    let url = `/invoice`;
    return this.axios.post(url,invoice).then(response => response.data);
  }
  updateInvoice(invoice) {
    let url = `/invoice/${invoice.invoiceId}`;
    return this.axios.put(url,invoice).then(response => response.data);
  }
  updateInvoicePayment(invoice) {
    let url = `/invoice/updatepayment/${invoice.invoiceId}`;
    return this.axios.put(url,invoice).then(response => response.data);
  }
  deleteInvoice(invoiceId) {
    let url = `invoice/${invoiceId}`;
    return this.axios.delete(url).then(response => response.data);
  }
}
const service = new InvoiceService();
export default service;
